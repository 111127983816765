import $ from 'jquery'

export default function mainMenu() {
  const $siteHeader = $('.site-header');

  const $mainNavigation = $siteHeader.find('.main-navigation');
  const $menuItemHasDropdown = $mainNavigation.find('.menu-item-has-children');
  const $menuSubMenu = $('.sub-menu');
  const $mainSubmenuTitles = $menuSubMenu.find('a[href^="#"]');

  const $document = $('document');
  const $window = $(window);
  const $body = $('body');

  let megaMenuOpen = false;

  /* create mega menu container */
  const $megaMenu = $('<nav class="desktop-menu" role="navigation" aria-labelledby="main-navigation"></nav>').appendTo('body');
  const $megaMenuOverlay = $('<span class="menu-overlay"></span>').appendTo('body');

  /* style non-link submenu titles */
  $mainSubmenuTitles.addClass('sub-menu--title');

  $menuItemHasDropdown.on('click', function(event) {
    event.stopPropagation();
    toggleMegaMenu($(this));
  });

  function toggleMegaMenu($thisElement) {
      if ($thisElement.hasClass('is-open')) {
        closeMegaMenu($thisElement);
      } else {
        toggleMegaMenuClasses($thisElement);
        setMegaMenuContent($thisElement);
      }
  }

  function toggleMegaMenuClasses($thisElement) {
    if (megaMenuOpen) {
      $thisElement.addClass('is-open');
      $megaMenu.toggleClass('show');
      $menuItemHasDropdown.removeClass('is-open');
    } else {
      $thisElement.toggleClass('is-open');
      $body.addClass('mega-menu-open menu-open');
      $siteHeader.toggleClass('show');
      $mainNavigation.toggleClass('show');
      megaMenuOpen = true;
    }
  }

  function removeMegaMenuClasses($thisElement) {
    $body.removeClass('mega-menu-open menu-open');
    $siteHeader.removeClass('show');
    $mainNavigation.removeClass('show');
    $menuItemHasDropdown.removeClass('is-open');
    $megaMenu.removeClass('show');
  }

  function setMegaMenuContent($thisElement) {
    let $subMenuItemOffset = $thisElement.offset();
    let $subMenuContent = $thisElement.find('.sub-menu').prop('outerHTML');

    $megaMenu.empty();
    $megaMenu.html($subMenuContent).addClass('show');
    $megaMenu.children('.sub-menu').css({ left: $subMenuItemOffset.left, position:'relative', width: 'max-content' });
  }

  function closeMegaMenu($thisElement) {
    $megaMenu.empty();
    removeMegaMenuClasses($thisElement);
    megaMenuOpen = false;
  }

  $window.on('click', function(e) {
    if ($(e.target).is($megaMenuOverlay)) {
      closeMegaMenu();
    }
  });

  $window.resize(function(){
    if ($window.width() <= 991) {
      closeMegaMenu();
    } else {
      if (megaMenuOpen) {
        let $menuOffset = $mainNavigation.find('.is-open').offset();
        $megaMenu.children('.sub-menu').css({ left: $menuOffset.left });
      }
    }
  });
}
