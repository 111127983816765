import $ from 'jquery'

// import homeSliders from './modules/home-sliders'
import mobileMenu from './modules/mobile-menu'
import mainMenu from './modules/main-menu'
import compoundModal from './modules/compound-modal'
import bootstrapModals from './modules/bootstrap-modals'
import bootstrapTabs from './modules/bootstrap-tabs'
import lazyLoadImages from './modules/lazy-load'
import animateAnchorLinks from './utils/animate-anchor-links'


$(function() {
    
    // homeSliders();
    mobileMenu();
    mainMenu();
    compoundModal();
    bootstrapModals();
    bootstrapTabs();
    lazyLoadImages();
    
    animateAnchorLinks.init();
});
