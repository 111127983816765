import $ from 'jquery'

export default function mobileMenu() {
  const $mobileMenuToggle = $('.menu-toggle');

  const $document = $('document');
  const $window = $(window);
  const $body = $('body');

  const $mobileMenu = $('.site-header .menus .main-wrap')
      .clone()
      .prepend('<a class="mobile-menu-back"><img src="/wp-content/themes/nrx/build/images/icon-gray-arrow-left.svg">Back</a>')
      .attr({'class': 'mobile-menu', 'role': 'navigation', 'aria-labelledby': 'main-navigation'})
      .appendTo('body');

  const $primaryMenu = $('.mobile-menu .primary-menu').prepend('<li class="menu-item"><a href="/">Home</a></li>');

  const $mobileMenuExtra = $('<nav class="extra-navigation"></nav>').appendTo($mobileMenu);
  $('.footer .social').clone().appendTo($mobileMenuExtra);
  $('.footer .copyright-info').clone().appendTo($mobileMenuExtra);

  const $mobileMenuFirstDropdowns = $mobileMenu.children('nav').children('ul').children('.menu-item-has-children').children('a');

  $mobileMenuFirstDropdowns.each(function(event) {
    const menuTitle = $(this).text();
    const menuHref = $(this).attr('href');
    const $dropdown = $(this).siblings('.sub-menu');
    $('<li class="mobile-menu--title"><a href="'+menuHref+'">' + menuTitle + '</a></li>').prependTo($dropdown);
  });

  $mobileMenuFirstDropdowns.on('click', function() {
    $mobileMenu.addClass('child-menu');
    $(this).closest('nav').addClass('active');
    $(this).siblings('.sub-menu').addClass('active');
  });

  $mobileMenu.find('.mobile-menu-back').on('click', function () {
    $mobileMenu.removeClass('child-menu').find('.sub-menu.active').removeClass('active');
  });

  $mobileMenuToggle.on('click', function(event) {
    event.stopPropagation();
    toggleMobileMenu();
  });

  function toggleMobileMenu() {
    $body.toggleClass('mobile-menu-open');
    $mobileMenuToggle.toggleClass('is-active');
  }

}
