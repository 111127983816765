import $ from 'jquery';
import "../../../node_modules/bootstrap/js/dist/modal.js";

export default function bootstrapModals() {
    let previousScrollY = 0;

    $(document).on('show.bs.modal', () => {
        previousScrollY = window.scrollY;
        setTimeout(function(){
            $("html").addClass('modal-open').attr("style","margin-top: -" + previousScrollY + 'px !important');
        }, 150);
    }).on('hidden.bs.modal', () => {
        $('html').removeClass('modal-open').css({marginTop: 0});
        window.scrollTo(0, previousScrollY);
    });

}
