import $ from 'jquery';
import "../../../node_modules/bootstrap/js/dist/modal.js";

export default function compoundModal() {
    const $modal = $('#site-modal');
    const $modalExit = $('#site-modal-exit');
    const $modalBody = $modal.find('.modal-body');
    const $exitCTA = $('.exit-modal');
    const $compoundCTA = $('.compound-modal');

    $compoundCTA.on('click', function (e) {
       e.preventDefault();
       let $this = $(this);
       let $compoundModalContent = $this.next('.compound-modal--body').html();
       $modalBody.html($compoundModalContent);
       $modal.modal('show');
    });

    $(document).on('click', '.exit-modal', function (e) {
        e.preventDefault();
        let $this = $(this);
        let $externalLink = e.target.href;

        if ( $externalLink === undefined ) {
            console.info('Add href to exit modal target.');
        }

        $modalExit.modal('show');
        const continueBtn = $modalExit.find('.continue').attr('href', $externalLink);
        continueBtn.on('click', function (event) {
            $modalExit.modal('hide');
        });
        const backBtn = $modalExit.find('.close-modal');
        backBtn.on('click', function (event) {
            $modalExit.modal('hide');
            return false;
        });
    });



}