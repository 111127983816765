import gsap from '../libs/gsap.min';
import ScrollTrigger from '../libs/ScrollTrigger.min';

export default function lazyLoadImages() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.config({limitCallbacks: true});

    let lazyLoadImages = document.querySelectorAll("[loading='lazy']");

    gsap.utils.toArray(lazyLoadImages).forEach(image => {
        image.addEventListener('load', loadImage);
        image.classList.add('lazy-fade-in');

        let loadImage = () => {
                if (image.dataset.src) {
                    image.src = image.dataset.src;
                    image.removeAttribute('data-src');
                    image.removeAttribute('loading');
                }
                if (image.dataset.background) {
                    let imgSrc = image.dataset.background;
                    image.style.backgroundImage = "url(" + imgSrc + ")";
                    image.removeAttribute('data-background');
                    image.removeAttribute('loading');
                }
                st && st.kill();
            },
            st = ScrollTrigger.create({
                trigger: image,
                onEnter: loadImage,
                onEnterBack: loadImage,
                once: true,
                // markers: true
            });
    });
}
