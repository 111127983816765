import '../../../node_modules/bootstrap/js/dist/tab';

export default function bootstrapTabs() {
    /* Activate first tab for all bootstrap tab instances */
    var firstTab = $('.nav-tabs li:first-child a');
    firstTab.tab('show').addClass('active');

    /* Add aria attrs to active tab content */
    var activeTabContent = $('.tab-content .active');
    activeTabContent.attr('aria-selected', 'true');

    var tabContainer = $('.tab-container');
    var navTabs = $('.nav-tabs');
    var navTabsLinks = $('.nav-tabs .nav-tabbed');

    var navTabToggle = $('.nav-tabs--toggle');
    var navTabToggleText = $('.nav-tabs--toggle-text');

    var currentTabText = $('.nav-tabs .nav-link.active').text();
    $(navTabToggleText).text(currentTabText);

    /* Mobile Dropdown Toggle */
    navTabToggle.click(function(e) {
        toggleActiveTab(e);
    });

    /* Mobile Dropdown Nav Items */
    navTabsLinks.click(function(e){
        toggleActiveTab(e);
        toggleActiveTabText(e);
    });

    function toggleActiveTab(e) {
        e.preventDefault();
        $(e.target).closest(tabContainer).find(navTabs).toggleClass('nav-tabs--show');
        $(e.target).closest(tabContainer).find(navTabToggle).toggleClass('active').attr('aria-expanded', function (i, attr) {
            return attr == 'true' ? 'false' : 'true'
        });
    }

    function toggleActiveTabText(e) {
        var currentTabText = e.target.text;
        $(e.target).closest(tabContainer).find(navTabToggleText).text(currentTabText);
    }

    /* Force initial state for mobile toggle on window resize */
    var resizeTimer;

    function resizeResetMobileToggle() {
        navTabs.removeClass('nav-tabs--show');
        navTabToggle.removeClass('active').attr('aria-expanded', function (i, attr) {
            return attr == 'true' ? 'false' : 'true'
        });
    };

    $(window).resize(function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeResetMobileToggle, 200);
    });
}